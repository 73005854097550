.article-information {
    width: 95%;
    margin: 3% auto 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
}

.article-information .information {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: flex-start;
}

.article-information .information span {
    color: green;
}

.article-information .information h1 {
    position: relative;
    font-size: 3rem;
    margin-top: 2%;
    margin-bottom: 2%;
}

.article-information .information h1::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: 0.3125rem;
    background-color: green;
    bottom: -5%;
    left: 0;
}

.article-information .information h2 {
    font-size: 2rem;
    color: rgba(128, 128, 128, 0.568);
    margin-top: 2%;
    margin-bottom: 2%;
    font-weight: 500;
    font-style: italic;
    text-align: start;
}

.article-information .information p {
    word-spacing: 4px;
    line-height: 110%;
    letter-spacing: 0.5px;
    margin-bottom: 1.5%;
}

.articles-plus {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.title-plus {
    position: relative;
    font-size: 2rem;
    margin-bottom: 2%;
}

.title-plus::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: 0.3125rem;
    background-color: green;
    bottom: -5%;
    left: 0;
}

