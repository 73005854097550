@media (min-width:900px) {
    .sidebar {
        display: block;
        float: right;
        position: relative;
    
    }

    .sidebar::after {
        content: '';
        position: absolute;
        width: 0.1rem;
        height: 10.3125rem;
        background-color: rgba(0, 128, 0, 0.37);
        bottom: auto;
        top: 0%;
        left: 121%;
    }
    
    .sidebar ul {
        color: silver;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
    }
    
}

@media (max-width: 899px) {
    .sidebar {
        display: none;
    }
}
