.secundario img {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    width: 100%;
    
}

.secundario h1 {
    margin-bottom: 1%;
    text-align: start;
}


.secundario p {
    margin-top: 3%;
    text-align: start;
    word-spacing: 4px;
    line-height: 110%;
    letter-spacing: 0.5px;
    margin-bottom: 1.5%;
}
