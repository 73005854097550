@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
}

body {
    background-color: #FAFAFA;
}

.container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.container .lateral {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.lateral ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
}

.card {
    width: 100%;
    height: 200px;
    font-size: 30px;
    background-color: #333;
    color: #fff;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width:1024px) {

    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}