
.principal img {
    border-radius: 7px;
    width: 20%;
    
}

.principal {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}


.principal p {
    margin-left: 1%;
    text-align: start;
    width: 100%;
    word-spacing: 4px;
    line-height: 110%;
    letter-spacing: 0.5px;
    margin-bottom: 1.5%;
}

