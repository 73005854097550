@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
.secundario img {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    width: 100%;
    
}

.secundario h1 {
    margin-bottom: 1%;
    text-align: start;
}


.secundario p {
    margin-top: 3%;
    text-align: start;
    word-spacing: 4px;
    line-height: 110%;
    letter-spacing: 0.5px;
    margin-bottom: 1.5%;
}


.principal img {
    border-radius: 7px;
    width: 20%;
    
}

.principal {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}


.principal p {
    margin-left: 1%;
    text-align: start;
    width: 100%;
    word-spacing: 4px;
    line-height: 110%;
    letter-spacing: 0.5px;
    margin-bottom: 1.5%;
}


.article-information {
    width: 95%;
    margin: 3% auto 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: baseline;
}

.article-information .information {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: flex-start;
}

.article-information .information span {
    color: green;
}

.article-information .information h1 {
    position: relative;
    font-size: 3rem;
    margin-top: 2%;
    margin-bottom: 2%;
}

.article-information .information h1::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: 0.3125rem;
    background-color: green;
    bottom: -5%;
    left: 0;
}

.article-information .information h2 {
    font-size: 2rem;
    color: rgba(128, 128, 128, 0.568);
    margin-top: 2%;
    margin-bottom: 2%;
    font-weight: 500;
    font-style: italic;
    text-align: start;
}

.article-information .information p {
    word-spacing: 4px;
    line-height: 110%;
    letter-spacing: 0.5px;
    margin-bottom: 1.5%;
}

.articles-plus {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.title-plus {
    position: relative;
    font-size: 2rem;
    margin-bottom: 2%;
}

.title-plus::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: 0.3125rem;
    background-color: green;
    bottom: -5%;
    left: 0;
}


@media (min-width:900px) {
    .sidebar {
        display: block;
        float: right;
        position: relative;
    
    }

    .sidebar::after {
        content: '';
        position: absolute;
        width: 0.1rem;
        height: 10.3125rem;
        background-color: rgba(0, 128, 0, 0.37);
        bottom: auto;
        top: 0%;
        left: 121%;
    }
    
    .sidebar ul {
        color: silver;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
    }
    
}

@media (max-width: 899px) {
    .sidebar {
        display: none;
    }
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
}

body {
    background-color: #FAFAFA;
}

.container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.container .lateral {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.lateral ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
}

.card {
    width: 100%;
    height: 200px;
    font-size: 30px;
    background-color: #333;
    color: #fff;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width:1024px) {

    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
